import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';

class About extends Component {

  render(props) {
  	const bgImage = this.props.data.file.childImageSharp.fluid.src;

  	return(

  		<Layout pageName="About">
	      <Helmet>
	        <title>About Best in Brighton</title>
	      </Helmet>
	      <div
	        className="page-hero"
	        style={{
	          backgroundImage: `url(${bgImage})`,
	        }}
	      >
	        <h1>About Best in Brighton</h1>
	      </div>

	      <div className="container container-content">
	      <p>Best in Brighton is a site recommending restaurants, shops, coffee shops and experiences in and around the city of Brighton UK. The recommendations are collated by local people who live in the area of Brighton so you know that they are recommendations that you can trust.</p>
		  <p>After all recommendations from locals, are the best! Finding local and independent places that you may not have found or be aware of otherwise. </p>
		   <p>If you’re a local and have some places to <a href="/recommend">recommend</a>, why not recommend them to us?</p>
	      </div>
  		</Layout>
  	); 
  }
}


export default About

export const query = graphql`
  query {
    file(relativePath: { eq: "banner-images/about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, fit: COVER) {
        	src
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`